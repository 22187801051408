import React from 'react';
import Hero from './Hero';
import AcquisitionCriteria from './AcquisitionCriteria';
import Services from './Services';
import Stats from './Stats';
import WhyChooseUs from './WhyChooseUs';
import Testimonials from './Testimonials';
import CaseStudies from './CaseStudies';

const Home = () => {
  return (
    <div className="bg-white">
      <Hero />
      <AcquisitionCriteria />
      <Services />
      <Stats />
      <WhyChooseUs />
      <Testimonials />
      <CaseStudies />
    </div>
  );
};

export default Home;