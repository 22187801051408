import React from 'react';
import { Target, Sprout, DollarSign } from 'lucide-react';
import { Link } from 'react-router-dom';
import confetti from 'canvas-confetti';

const CriteriaCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300">
    <Icon className="h-8 w-8 text-primary-600 mb-4" />
    <h4 className="text-xl font-semibold mb-2 uppercase font-display">{title}</h4>
    <p className="text-secondary-600 font-display">{description}</p>
  </div>
);

const AcquisitionCriteria = () => {
  const handleGetInTouch = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-4 uppercase font-display">Our Acquisition Criteria</h2>
        <p className="text-xl text-center mb-12 max-w-3xl mx-auto text-secondary-600 font-display">
          We're looking for SaaS companies that match the following criteria
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          <CriteriaCard
            icon={DollarSign}
            title="Revenue & Profitability"
            description="$50k - $1M ARR with 65%+ profit margin (excluding founders' salaries)"
          />
          <CriteriaCard
            icon={Target}
            title="Target Market"
            description="SaaS targeting mainly SMB customers or prosumers, with a clear market fit and growth potential"
          />
          <CriteriaCard
            icon={Sprout}
            title="Growth & Stability"
            description="Mostly organic growth with proven market demand and an easy to maintain, stable codebase"
          />
        </div>

        <div className="bg-white p-8 rounded-lg shadow-md text-center max-w-2xl mx-auto">
          <h3 className="text-2xl font-bold mb-4 uppercase font-display">Does Your SaaS Match Our Criteria?</h3>
          <p className="text-secondary-600 mb-6 font-display">
            If your company aligns with our requirements and you have a positive, ethical team, we'd love to hear from you.
          </p>
          <Link 
            to="/contact"
            onClick={handleGetInTouch}
            className="inline-block bg-primary-600 text-white px-8 py-3 rounded-md font-semibold hover:bg-primary-700 transition duration-300 uppercase font-display"
          >
            Get in Touch
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AcquisitionCriteria;